@import "colors";

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $line-light-primary;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-light-gray;
  border-radius: 4px;

  &:hover {
    background-color: $secondary-light-gray;
  }
}
