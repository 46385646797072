@import "src/styles/typography";
@import "src/styles/colors";

.overlay {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 60%);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 310px;
  max-width: 410px;
  min-height: 160px;
  padding: 24px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.header-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.header-title {
  @include title-2($pitch-black-color);
}

.actions {
  display: flex;
  gap: 12px;
}

.close-button {
  cursor: pointer;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.close-icon {
  fill: $blue-primary;
}
