@import "src/styles/typography";
@import "src/styles/colors";

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include title-2($pitch-black-color);
}

.description {
  @include secondary-text($text-light-secondary);
}

.error {
  @include secondary-text($text-red);
}
