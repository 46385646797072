@import "src/styles/colors";

.root {
  width: 100%;
  height: 100%;
  background-color: $bg-light-primary;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 424px;
  height: 100%;
  padding: 24px 72px 42px 64px;
  background-color: $pure-white-color;
  border-left: 8px solid $blue-primary;
}
