@import "../../../../styles/colors";
@import "src/styles/media";
@import "src/styles/typography";

.dialog {
  width: 500px;
  @include mobile {
    width: 350px;
  }
}

.categories-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icons-wrapper {
  display: flex;
  align-items: center;

  button {
    padding: 0;
    margin-right: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    visibility: hidden;
  }
}

.category-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  &:hover {
    .icons-wrapper {
      button {
        visibility: visible;
      }
    }
  }
}

.category {
  display: flex;
  align-items: center;
  width: 350px;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 16px;
  font-family: $nunito-regular;
  @include primary-text-app-base;

  & > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: rgb(40 58 95 / 3%);
  }
}

.category-icon {
  align-self: start;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;

  svg {
    width: 200px;
    height: 200px;
    margin-bottom: 24px;
  }
}

.new-category {
  width: 100%;
}
