@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/media";


.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgb(40 58 95 / 5%);
}

.button {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 8px 12px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  @include mobile {
    flex-wrap: wrap;
    height: auto;
  }
  &:hover {
    .backdrop {
      visibility: visible;
    }
  }

  &-blue {
    background-color: $light-blue-color;

    @include button($dark-blue-color);
  }

  &-purple {
    background-color: $secondary-lilac-20;

    @include button($dark-purple-color);
  }

  &-pink {
    background-color: $light-pink-color;

    @include button($dark-pink-color);
  }

  &-orange {
    background-color: $light-orange-color;

    @include button($bg-dark-primary);
  }
}

.icon {
  height: 48px;
  margin-right: 8px;

  svg {
    width: 48px;
    height: 48px;
  }
}
