@import "src/styles/colors";

.spinner-wrapper {
  display: flex;
  align-self: center;
  height: 100vh;
}

.spinner {
  margin: auto;
  border: 3px solid #f3f3f3;
  border-top: 3px solid $blue-primary;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
