/* =============================================
=        Foundation - Typography              =
============================================= */

@font-face {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 400;
  src:
    url("/assets/fonts/regular/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"),
    url("/assets/fonts/regular/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff") format("woff"),
    url("/assets/fonts/regular/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf") format("truetype"),
    url("/assets/fonts/regular/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: Nunito-Semibold;
  font-style: normal;
  font-weight: 600;
  src:
    url("/assets/fonts/semibold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2") format("woff2"),
    url("/assets/fonts/semibold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-600.woff") format("woff"),
    url("/assets/fonts/semibold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf") format("truetype"),
    url("/assets/fonts/semibold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-600.eot") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: Nunito-Bold;
  font-style: normal;
  font-weight: 700;
  src:
    url("/assets/fonts/bold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2") format("woff2"),
    url("/assets/fonts/bold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-700.woff") format("woff"),
    url("/assets/fonts/bold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf") format("truetype"),
    url("/assets/fonts/bold/nunito-v23-latin-ext_latin_cyrillic-ext_cyrillic-700.eot") format("embedded-opentype");
  font-display: swap;
}
