@import "src/styles/media";

.dialog {
  width: fit-content;
  min-width: 400px;
  @include mobile {
    min-width: 300px;
  }
}

.categories-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 16px;

  &:hover {
    background-color: rgb(40 58 95 / 3%);
  }
  @include mobile {
    width: 300px;
  }
}

.category-icon {
  height: 48px;
  width: 48px;
  margin-bottom: 4px;
  margin-right: 8px;
}
