@import "../../../../../../styles/typography";

.dialog {
  width: fit-content;
  padding: 20px;
}

.input {
  margin-bottom: 12px;
}

.action-wrapper {
  display: flex;

  button {
    flex-grow: 1;
  }
}

.confirm {
  margin-right: 8px;
}
