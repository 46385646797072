$nunito-regular: nunito-regular, sans-serif;
$nunito-semibold: nunito-semibold, sans-serif;
$nunito-bold: nunito-bold, sans-serif;

@mixin main($color) {
  font-style: normal;
  font-stretch: normal;

  @if $color != "" {
    color: $color;
  }
}

@mixin regular {
  font-family: $nunito-regular;
  font-weight: 400;
}

@mixin semi-bold {
  font-family: $nunito-semibold;
  font-weight: 600;
}

@mixin bold {
  font-family: $nunito-bold;
  font-weight: 700;
}

@mixin title-1-base {
  font-size: 24px;
  line-height: 30px;
}

@mixin title-2-base {
  font-size: 20px;
  line-height: 26px;
}

@mixin primary-text-app-base {
  font-size: 16px;
  line-height: 22px;
}

@mixin secondary-text-base {
  font-size: 14px;
  line-height: 20px;
}

@mixin text-category-base {
  font-size: 14px;
  line-height: 18px;
}

@mixin button-base {
  font-size: 16px;
  line-height: 20px;
}

@mixin title-1($color: "") {
  @include main($color);
  @include semi-bold;
  @include title-1-base;
}

@mixin title-2($color: "") {
  @include main($color);
  @include semi-bold;
  @include title-2-base;
}

@mixin primary-text-app($color: "") {
  @include main($color);
  @include regular;
  @include primary-text-app-base;
}

@mixin bold-chek-text($color: "") {
  @include main($color);
  @include bold;
  @include primary-text-app-base;
}

@mixin primary-text-chek($color: "") {
  @include main($color);
  @include regular;
  @include primary-text-app-base;
}

@mixin secondary-text($color: "") {
  @include main($color);
  @include regular;
  @include secondary-text-base;
}

@mixin text-category($color: "") {
  @include main($color);
  @include semi-bold;
  @include text-category-base;
}

@mixin button($color: "") {
  @include main($color);
  @include semi-bold;
  @include button-base;
}
