@import "src/styles/typography";
@import "src/styles/colors";

[data-reach-dialog-overlay] {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(40 58 95 / 30%);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  overflow: auto;
}

.dialog {
  position: relative;
  z-index: 5;
  padding: 24px 32px;
  border-radius: 16px;

  &-scroll {
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    .content-wrapper {
      min-height: 300px;
    }
  }
}

.title {
  margin: 0 40px 16px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: normal;

  @include title-2;
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  svg {
    fill: $blue-primary;
  }
}
