@import "src/styles/colors";

.button {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  color: $text-white-secondary;
  cursor: pointer;
  background-color: $blue-primary;
  border: none;
  border-radius: 8px;
}
