@import "src/styles/colors";
@import "src/styles/media";

.pagination-wrapper {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 6px 0;
  cursor: pointer;
  background-color: $pure-white-color;
  border-radius: 3px;
  margin-right: 8px;
  @include mobile {
    min-width: 20px;
    margin-right: 6px;
  }

  &:hover:not(.selected, .disabled) {
    background-color: $line-light-primary;
  }
}

.disabled > svg {
  fill: $text-light-secondary;
}

.selected {
  background-color: $secondary-purple-20;
}

.arrow-right {
  fill: $pitch-black-color;
}
