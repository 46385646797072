@import "reset";
@import "fonts";
@import "scroll";

/* Override default box model */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family:
    Nunito-Regular,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}
button, input {
  font-family: Nunito-Regular, sans-serif;
  font-size: 16px;
}
