$pitch-black-color: #000;
$pure-white-color: #fff;
$dark-primary: #283a5f;
$text-light-secondary: #77869e;
$blue-primary: #6895f5;
$text-white-secondary: #fff;
$text-red: #ff3b30;
$line-light-primary: #eff0f3;
$bg-light-primary: #f9faff;
$bg-dark-primary: #fd6f7b;
$icon-secondary: #97abd7;
$primary-light-gray: #cecece;
$secondary-light-gray: #9e9e9e;

$primary-purple-100: #6895f5;
$secondary-purple-20: #dae7fd;
$primary-lilac-100: #9567f9;
$secondary-lilac-20: #e7e1ff;
$primary-yellow-100: #ffcb75;
$secondary-yellow-20: #fff6e8;
$primary-orange-100: #ffb482;
$secondary-orange-20: #fff0e5;
$illustration-primary: #6895f5;
$illustration-secondary: #fd6f7b;

$primary-green-100: #53bee0;
$secondary-green-20: #cbecf6;
$primary-coral-100: #fd6f7b;
$secondary-coral-20: #ffeaeb;
$primary-pink-100: #fc5faa;
$secondary-pink-20: #ffedf6;

$primary-common-dark: #ffb1ac;
$secondary-common-100: #ffd8d6;
$secondary-common-20: #fff7f7;
$split-bill-mine-btn: #dee0fc;
$split-bill-card: #f5f7ff;

$light-blue-color: #CFE0FD;
$dark-blue-color: #4D64BD;
$dark-purple-color: #715FB8;
$light-pink-color: #FFEBF5;
$dark-pink-color: #FF72B6;
$light-orange-color: #FFE5E5;
