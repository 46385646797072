.categories-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.category-label {
  display: flex;
  align-items: center;
  width: 350px;
  margin-right: 8px;
}

.category-icon {
  height: 48px;
  width: 48px;
  margin-bottom: 4px;
  margin-right: 8px;
}
