@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/media";

.page {
  padding: 16px 8px;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  @include mobile {
    flex-direction: column;
  }
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 40px 72px;
  min-width: 600px;
  @include mobile {
    min-width: auto !important;
    margin: 0;
  }
}

.icon {
  border-radius: 50%;
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
}

.name {
  margin-bottom: 16px;

  @include primary-text-chek;
}

.price {
  @include title-2;
}

.items-wrapper {
  margin: 40px;
}

.item-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.price-wrapper {
  text-align: right;
}

.price-item {
  @include secondary-text($text-light-secondary);
}

.item-name {
  grid-column: 1/3;
}

.subtitle {
  margin-bottom: 16px;

  @include bold-chek-text;
}

.divider {
  margin: 8px 0;
}

.categories {
  margin-left: 40px;
  margin-bottom: 40px;
}

.categories-amount {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $text-light-secondary;
}

.edit-icon {
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: -2px;
}

.details {
  margin-left: 40px;
}

.btn-download {
  margin-top: 16px;
  white-space: nowrap;
}

.icon-wrapper {
  margin-right: 8px;
  margin-bottom: -4px;
}
