@import "src/styles/colors";
@import "src/styles/media";

.dialog {
  @include mobile {
    width: 330px;
    align-items: center;
    justify-content: center;
  }
}

.stores {
  display: grid;
  gap: 15px;
}

.link {
  display: block;
  text-decoration: none;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
