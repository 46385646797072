@import "src/styles/typography";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  text-align: center;
}

.title {
  margin-top: 18px;
  margin-bottom: 8px;

  @include title-2-base;
}

.message {
  margin-bottom: 26px;
}

.actions {
  display: flex;

  & > button:first-child {
    margin-right: 12px;
  }
}
