@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/media";

$row-height: 52px;

.table {
  width: 100%;
  overflow: hidden;
  border-collapse: separate;
  border-radius: 16px 16px 0 0;
}

.with-click-handler {
  cursor: pointer;

  &:hover {
    background-color: rgb(40 58 95 / 5%);
  }
}

.extra-column {
  width: 5%;
  height: $row-height;
  padding: 0 !important;
  text-align: right !important;

  & > * {
    visibility: hidden;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;

  &_left {
    justify-content: start;
  }

  &_right {
    justify-content: end;
  }

  &_center {
    justify-content: center;
  }
}

.header {
  height: 72px;
  background: $split-bill-card;
  @include mobile {
    height: 52px;
  }
}

.row {
  height: $row-height;

  &:hover {
    .extra-column {
      & > * {
        visibility: revert;
      }
    }
  }
}

.cell {
  padding: 12px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid $line-light-primary;
  @include mobile {
    padding: 8px;
  }

  &_left {
    text-align: left;
  }

  &_right {
    text-align: right;
  }

  &_center {
    text-align: center;
  }
}

.header-cell {
  border: none;
}

.cell-sort {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.table-content {
  flex-grow: 1;
  width: 100%;
  height: 1000px;
  overflow: auto;
}

.table-thead {
  position: sticky;
  top: 0;
}

.table-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1000px;
  height: 500px;
  overflow: hidden;
}

.sort-icon-wrapper {
  display: inline-block;
  margin-left: 6px;
}

.sort-arrow-down {
  display: block;
  width: 8px;
  height: 6px;
  margin-bottom: 2px;

  &_reverse {
    transform: rotate(180deg);
  }
}
