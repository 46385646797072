@import "src/styles/typography";
@import "src/styles/colors";
@import "src/styles/media";

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px 16px;

  @include mobile {
    flex-wrap: wrap;
  }
}

.back {
  height: 40px;
  width: 40px;
  background-color: $blue-primary;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;

  svg {
    width: auto;
    height: 14px;
    fill: white;
    margin-left: -1px;
  }
}

.header {
  @include title-1-base;
  @include bold;
  @include mobile {
    margin: 0 auto;
    text-align: center;
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.icon {
  width: 72px;
  height: 72px;
  margin-right: 12px;
}

.subheader {
  @include primary-text-app-base;
  @include regular;
}

.divider {
  margin: 0 !important;
  margin-bottom: 8px !important;
}

.actions {
  display: flex;
  @include mobile {
    margin: 5px auto;
  }

  & > * {
    margin-left: 12px;
  }
}
