@import "src/styles/colors";
@import "src/styles/typography";

.icon-button {
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.context-menu-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.context-menu {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: $blue-primary;
  padding: 12px;
  cursor: default;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }

  @include text-category($text-white-secondary);
}
