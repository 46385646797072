@import "styles/vars";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  margin-left: $sidebar-min-width;
}
