@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/vars";

.sidebar {
  position: fixed;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: $sidebar-min-width;
  height: 100%;
  padding: 32px 0 8px;
  background-color: $pure-white-color;
  border-left: 8px solid $blue-primary;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  transition: all 0.3s;
}

.is-expanded {
  align-items: start;
  width: 262px;
}

.nav-items {
  width: 100%;
}

.nav-item {
  display: grid;
  grid-template-columns: 64px;
  align-items: center;
  height: 48px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;

  @include title-2($pitch-black-color);

  &:hover {
    background-color: $bg-light-primary;
  }

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  &-title {
    &:hover {
      background-color: revert;
    }
  }

  &-expanded {
    grid-template-columns: 64px 1fr;
  }
}

.nav-icon {
  justify-self: center;
  width: 24px;
  height: 24px;
}

.pro-check-title {
  @include title-1($pitch-black-color);
}

.logo {
  justify-self: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
