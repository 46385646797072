@import "src/styles/colors";
@import "src/styles/typography";

.dialog {
  width: fit-content;
  min-width: 400px;
}

.label {
  margin-bottom: 8px;

  @include secondary-text($text-light-secondary);
}

.action {
  width: 100%;
}
