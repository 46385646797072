@import "src/styles/typography";
@import "src/styles/media";

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 8px;
}

.header {
  height: 72px;
  @include mobile {
    height: auto;
  }
}

.icon-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.table {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
}

.plug-image {
  width: 400px;
  height: 400px;
}

.plug-text {
  @include title-2-base;
}

.pagination-wrapper {
  margin-top: 12px;
}

.icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  @include mobile {
    width: 32px;
    height: 32px;
  }
}

.cell-sum {
  white-space: nowrap;
}

.cell-date {
  white-space: nowrap;
}
