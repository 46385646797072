@import "src/styles/typography";
@import "src/styles/colors";

.login-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include title-2($pitch-black-color);
}

.secondary-title {
  @include secondary-text($text-light-secondary);
}
